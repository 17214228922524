import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/pt-br'

export const normalizeAuthData = ({ data }) => ({
  token: data?.token,
})

export const normalizeUserData = ({ data }) => ({
  accessRoles: data?.accessRoles || {},
  defaultScreen: data?.defaultScreen || '',
  userEmail: data?.login || '',
  userName: data?.name || '',
  userLanguage: data?.defaultLanguage ?? 'PT_BR',
  userId: data?.id || 0,
  userGroup: data?.group || 0,
  commercialId: data.commercialId || null,
  company: data?.company || {},
  userXCompanyList: data?.userXCompanyList || [],
  userXProductList: data?.userXProductList || [],
  companyId: data?.companyId || 0,
  userTheme: data?.theme?.type || 'light',
  isLatamUser: false,
})

export const normalizeTableSettingsData = (values) => {
  return {
    columns: values?.data?.data?.columns || [],
    fixedLeftColumns: values?.data?.data?.fixedLeftColumns || [],
    fixedRightColumns: values?.data?.data?.fixedRightColumns || [],
    hiddenColumns: values?.data?.data?.hiddenColumns || [],
    margimGroupColumns: values?.data?.data?.margimGroupColumns || [],
    order: values?.data?.data?.order || [],
    pageSizes: values?.data?.data?.pageSizes || [],
    providers: values?.data?.data?.providers || {},
    purchaseGroupColumns: values?.data?.data?.purchaseGroupColumns || [],
    salesGroupColumns: values?.data?.data?.salesGroupColumns || [],
    colouredCurrencyGroupColumns:
      values?.data?.data?.colouredCurrencyGroupColumns || [],
    totalRowHasTitle: values?.data?.data?.totalRowHasTitle || false,
  }
}

export const normalizeFilterSettingsData = ({ data }) => ({
  filterPossibilities: data?.data?.filterPossibilities || [],
  groupingPossibilities: data?.data?.groupingPossibilities || [],
  groupsPossibilities: data?.data?.groupsPossibilities || [],
})

export const normalizeListData = ({ data }) => {
  return data?.data || []
}

export const normalizeCardData = ({ data }) => {
  return (
    data?.data?.map((item) => ({
      values: [
        {
          value: item.value,
          currencyCode: 'BRL',
          currency: { code: 'BRL', numericCode: 986, symbol: 'R$' },
        },
      ],
      ...item,
    })) || []
  )
}

export const normalizeListDataCurrency = (listState, tableState) => {
  const currencyColumnsArray = []

  if (tableState?.providers?.currency) {
    for (let columnName of tableState?.providers?.currency) {
      currencyColumnsArray.push(columnName)
    }
  }

  const newListDataState = !_.isEmpty(currencyColumnsArray)
    ? listState.map((item) => {
        for (let value of currencyColumnsArray) {
          item[value] = `${item?.currency?.symbol} ${item[value]}`
        }

        return item
      })
    : listState

  return newListDataState
}

export const normalizeSizeData = ({ data }) => {
  return data?.data || 0
}

export const normalizeTotalData = ({ data }) => {
  return data?.data || {}
}

export const normalizeMapData = ({ data }) => {
  return data?.data && data?.data
}

export const normalizePermissionsAccessData = ({ data }) => {
  return data?.data || {}
}

export const normalizeCustomersData = ({ data }) => {
  return data?.data || {}
}

export const normalizeBillingData = ({ data }) => {
  return data?.data || {}
}

export const normalizeDashboardData = ({ data }) => {
  return data?.data || {}
}

export const normalizeCommissionData = ({ data }) => {
  return data?.data || {}
}

export const normalizeTariffData = ({ data }) => {
  return data?.data || []
}

export const normalizeCommercialData = ({ data }) => {
  return data?.data || []
}

export const normalizeTariffCreation = (data) => {
  const tariff = {
    operatorId: data?.operator?.value,
    productId: data?.product?.value,
    type: data?.tariffType?.value,
    description: data?.tariffName,
    details: data?.details || {},
    companyId: data?.companyId || null,
    currencyCode: data?.currency?.code || null,
  }
  if (data?.billingPayzenType?.value >= 0) {
    tariff.billingPayzenType = data?.billingPayzenType?.value
  }

  if (data?.billingVeripagType?.value >= 0) {
    tariff.billingVeripagType = data?.billingVeripagType?.value
  }

  if (data?.billingLSSType?.value >= 0) {
    tariff.details.billingType = data?.billingLSSType?.value
  }

  if (data?.company?.value >= 0) {
    tariff.companyId = data?.company?.value
  }

  return tariff
}

export const normalizeTariffUpdate = (data) => {
  const {
    details,
    tariffName,
    tariffType,
    cycleStart,
    id,
    productId,
    operatorId,
  } = data
  const tariff = {
    cycleStart,
    id,
    productId,
    operatorId,
    type: tariffType.value,
    description: tariffName,
    details,
  }
  console.log(data, 'data')
  return tariff
}

export const normalizeBillingProcessData = ({ data }) => {
  return data?.data || []
}

export const normalizeAccountsData = ({ data }) => {
  return data?.data || []
}

export const normalizeEmailsData = ({ data }) => {
  return data?.data?.data || []
}

export const normalizeConciliationData = ({ data }) => {
  return data?.data || []
}

export const normalizeReportsData = ({ data }) => {
  return data?.data || []
}

export const normalizePayzenShopData = ({ data }) => {
  return data?.data || []
}

export const normalizeOpportunitiesData = ({ data }) => {
  return data?.data || []
}
export const normalizeData = ({ data }) => {
  return data?.data || []
}

export const normalizeCancelledChips = ({ data }) => {
  const normalizedData =
    data?.data && Array.isArray(data?.data)
      ? data?.data?.map((row) => {
          return [
            {
              align: 'left',
              value: moment(row.date)
                .locale('pt-Br')
                .format('DD [de] MMMM [de] YYYY'),
            },
            {
              align: 'right',
              value: row.count,
            },
          ]
        })
      : []

  return normalizedData
}
